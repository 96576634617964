import React from 'react'

const AppGlobal = () => {
  return (
    <div>
      choose or add your categ
    </div>
  )
}

export default AppGlobal
